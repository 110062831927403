<template>
  <div class="use-device">
    <x-table
      :no-text-data="CA('useDevice_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @add="add"
      @search="search"
      @page-change="pageChange"
      @loadExpend="loadExpend"
      @page-size-change="pageSizeChange"
    ></x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      @on-visible-change="modalShow"
      :width="450"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="100">
        <FormItem label="设备名称" prop="name">
          <Input v-model="form.name" style="width:300px"></Input>
        </FormItem>
        <FormItem label="设备类型" prop="type">
          <Select v-model="form.type" style="width:300px">
            <Option v-for="item in category" :value="item.id" :key="item.id">{{
              item.name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="施用效率" prop="efficiency">
          <Input v-model="form.efficiency" style="width:300px"></Input>
        </FormItem>
        <FormItem label="设备功能" prop="function">
          <Input v-model="form.function" style="width:300px"></Input>
        </FormItem>
        <FormItem label="联系人" prop="linkName">
          <Input v-model="form.linkName" style="width:300px"></Input>
        </FormItem>
        <FormItem label="联系电话" prop="linkTel">
          <Input v-model="form.linkTel" style="width:300px"></Input>
        </FormItem>
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>
  </div>
</template>

<script>
import CU from "@/common/util";
export default {
  name: "",
  data() {
    return {
      table: {
        columns: [
          {
            title: "施用设备",
            minWidth: 100,
            key: "name",
          },
          {
            title: "设备类型",
            minWidth: 100,
            key: "typeName",
          },
          {
            title: "施用效率",
            minWidth: 100,
            key: "efficiency",
          },
          {
            title: "设备功能",
            minWidth: 100,
            key: "function",
          },
          {
            title: "所属组织/个人",
            minWidth: 100,
            key: "companyName",
          },
          {
            title: "联系人",
            minWidth: 100,
            key: "linkName",
          },
          {
            title: "联系电话",
            minWidth: 100,
            key: "linkTel",
          },
          {
            title: "操作",
            width: 150,
            render: (h, { row }) => {
              return (
                <div>
                  {this.CA("useDevice_edit") && (
                    <a
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      编辑
                    </a>
                  )}
                  {this.CA("useDevice_del") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a>删除</a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      search_data: {},
      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      form: {
        id: "",
        name: "",
        type: "",
        function: "",
        efficiency: "",
        linkName: "",
        linkTel: "",
      },
      rules: {
        name: [{ required: true, message: "请填写施用设备名称" }],
        type: [{ required: true, message: "请选择使用设备类型" }],
        function: [{ required: true, message: "请填写施用设备功能" }],
        efficiency: [{ required: true, message: "请填写设备施用效率" }],
        linkName: [{ required: true, message: "请填写联系人" }],
        linkTel: [
          { required: true, message: "请填写联系电话" },
          {
            validator(rule, value, callback) {
              if (!CU.validatePhone(value)) {
                callback(rule.message);
                return;
              }
              callback();
            },
            message: "请填写正确的手机号码",
          },
        ],
      },
      category: [],
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "新增施用设备",
            ca: "useDevice_add",
          },
          loadExpend: {
            loadExpendName: "导出",
            ca: "useDevice_export",
          },
          width: 200,
          filterBox: [
            {
              conditionName: "设备名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            {
              conditionName: "设备类型",
              component: "select",
              field: "type",
              data: this.category,
              defaultValue: "",
              parameterField: "id",
              showField: "name",
            },
          ],
        },
        page: this.page,
      };
      return config;
    },
  },
  methods: {
    add() {
      this.modal = {
        show: true,
        title: "新增施用设备",
        submitLoading: false,
      };
    },

    search(data) {
      this.page.pageNo = 1;
      this.search_data = data;
      this.getList();
    },

    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize
      this.getList()
    },

    getList() {
      if (!this.CA("useDevice_check")) return;
      this.table.loading = true;
      this.$post(this.$api.USE_DEVICE.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    getCategory() {
      this.$post(this.$api.SYS_CATEGORY.LIST, {
        pid: "218",
      }).then((res) => {
        this.category = res.list;
      });
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.type = row.type + "";
      this.modal = {
        show: true,
        title: "编辑施用设备",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.USE_DEVICE.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        type: "",
        function: "",
        efficiency: "",
        linkName: "",
        linkTel: "",
      };
      this.$refs.form.resetFields();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        this.modal.submitLoading = true;
        this.$post(
          params.id ? this.$api.USE_DEVICE.EDIT : this.$api.USE_DEVICE.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
    loadExpend() {
      this.$post(this.$api.USE_DEVICE.LIST, {
        ...this.search_data,
      }).then((res) => {
        let n = res.list;
        let columns = this.table.columns.map((item) => item.title);
        columns.pop();
        let data = n.map((row) => {
          let row_data = this.table.columns.map((el) => {
            if (el.key) {
              return row[el.key];
            }
          });
          return row_data;
        });
        CU.exportExcel(columns, data, "粪污施用设备");
      });
    },
  },
  mounted() {
    this.getList();
    this.getCategory();
  },
};
</script>

<style lang="less" scoped>
.use-device {
  width: 100%;
  height: 100%;
}
</style>